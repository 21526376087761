.zeroList table{
    width: 100%;
    border: 1px solid rgb(221, 224, 224);
    background-color: #FFFFFF;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 8px;
    th{
        text-align: left;
        padding-left: 20px;
        height: 50px;
        border: 1px solid rgb(221, 224, 224);
    }
    tr{
        td{
            text-align: left;
            padding-left: 20px;
            border: 1px solid rgb(221, 224, 224);
            height: 50px;
        }
        
    }
}