.root {
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  padding: 20px;
  padding-bottom: 0;
  border-radius: 8px;
}

.title {
  height: auto;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 20px 0;
  font-size: 36px;
  color: #179c49;
}

.subTitle {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  & .subTitleLeft {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    & .subTitleLeftText {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      font-size: 20px;
      margin: 10px 0;
      text-transform: capitalize;
      color: #3f3f3f;
      font-weight: 600;
    }
  }

  & .subTitleRight {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 20px;

    & .subTitleRightBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      background-color: #179c49;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      outline: none;
      border: none;
      padding: 5px 10px;
      transition: all 0.3s ease-in-out;
    }

    & .subTitleRightBtn:hover {
      scale: 1.1;
      transition: all 0.3s ease-in-out;
    }
  }
}

.loadingWrap {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
