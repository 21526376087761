.title{
    text-align: center;
    font-size: 20px;
}
.form{
    width: 100%;
    margin-top: 30px;
    margin-right: 20px;
}
.button{
    float: right;
    margin-top: 40px;
    .btnCancel{
        margin-left: 20px;
        margin-right: -8px;
    }
}