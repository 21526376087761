.customInput{
    .label{
        font-size: 14px;
        font-weight: 600;
    }
    .input{
        width: 98%;
        border-radius: 4px;
        height: 24px;
        border: solid 1px rgb(206, 199, 199);
        margin-top: 4px;
        padding-left: 10px;
    }
    padding-bottom: 10px;
}
.error{
    color: rgb(238, 46, 46);
    font-size: 14px;
}