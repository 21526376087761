.filter{
    .action{
        display: flex;
        gap: 10px;
    }
    .form{
        margin-bottom: 20px;
        display: flex;
        gap: 10px;
    }
}