.root {
}
.copy{
  width: 30px;
  cursor: pointer;
  margin-bottom: -6px;
  background-color: rgb(250, 249, 244);
  border-radius: 4px;
}
.copy:hover{
  background-color: rgb(39, 213, 12);
}
.orderListWrap {
  margin-top: 10px;
  & .statusTab {
    & .statusTabItem {
      display: flex;
      width: 100%;
      gap: 10px;
      & .statusTabItemTitle {
        width: 100%;
        padding: 4px 10px;
        text-align: center;
        cursor: pointer;
        height: 55px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;
        font-size: 16px;
        font-weight: 500;
      }

      & .statusTabItemTitle.active {
        background-color: #f5f5f5;
        color: #179c49;
      }

      & .statusTabItemTitle:hover {
        background-color: #cbedc7;
        color: #179c49;
      }
    }
  }

  & .orderList {
    display: flex;
    width: 100%;
    gap: 10px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;

    & table {
      width: 100%;

      & thead {
        & tr {
          & th {
            padding: 10px;
            color: #000;
            font-size: 16px;
            font-weight: 600;
            text-align: left;
            border: #e6ece8 1px solid;
          }
        }
      }

      & tbody {
        & tr {
          & td {
            padding: 10px;
            text-align: left;
            font-size: 14px;
            font-weight: 400;
            border: #e6ece8 1px solid;
          }
        }
      }
    }
  }
}

table {
  border-collapse: collapse;
}

table th,
td {
  border: 2px solid #fff;
}

th {
  border-top: none !important;
}

.iconWrap {
  width: 35px;
  text-align: center !important;
  cursor: pointer;
  transition: 0.2s ease-in-out all;

  & img {
    width: 22px;
  }
}

.iconWrap.active {
  background-color: #e2eeec;
  transition: 0.2s ease-in-out all;
}

.item {
  cursor: pointer;
}

.item:hover {
  background-color: #ebf3f5;
  transition: 0.2s ease-in-out all;
}

.item.active {
  background-color: #ebf0f4;
  transition: 0.2s ease-in-out all;
}

.itemDetail {
  background-color: #ebf3f5;

  & .itemDetailWrap {
    & table th {
      border-right: 2px solid #fff !important;
      border: none;
    }

    & table td {
      border-right: 2px solid #eee6e2 !important;
      border: none;
    }

    & tbody {
      background-color: #fff;
    }

    & .itemDetailInfoWrap {
      width: 350px;
      & p {
        margin: 4px;
        font-size: 16px;
      }
    }

    & .itemDetailProduct {
      & .itemDetailProductImg {
        width: 160px;
        height: 160px;
        border: 1px solid #eee;
        border-radius: 5px;
        overflow: hidden;
        margin-right: 10px;
        & img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  & table th:last-child {
    border-right: none !important;
    text-align: center !important;
  }

  & table td:last-child {
    border-right: none !important;
    text-align: center !important;
  }
}

.tdAction {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  & div {
    background-color: #fff;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.2s ease-in-out all;
  }

  & div:hover {
    background-color: #f5f5f5;
    transition: 0.2s ease-in-out all;
  }

  & .pending {
    color: #179c49;
  }

  & .canceled {
    color: #ff0000;
  }

  & .finished {
    color: #000;
  }
}

.loadingWrap {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noneData {
  text-align: center !important;
}

.bagId {
  margin: 10px 0;
  font-size: 16px;
  font-weight: 600;
}
