.root {
  margin-top: 10px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.searchWrap {
  & form {
    display: flex;
    margin-right: 20px;
    gap: 20px;
    & .inputWrap {
      & input {
        width: 350px;
        height: 40px;
        padding: 0 20px;
        margin: 0 0 0 -10px;
        outline: none;
        font-size: 1rem;
        border-radius: 4px 0 0 4px;
      }
    }

    & .buttonWrap {
      & button {
        width: 200px;
        height: 43px;
        padding: 0 10px;
        margin: 0 -10px;
        font-size: 1rem;
        cursor: pointer;
        border-radius: 0 4px 4px 0;
      }
    }
  }
}
