.chart{
    text-align: left;
    padding: 0 20px;
    background-color: #fff;
    height: auto;
    width: 45%;
    border-radius: 8px;
    box-shadow: 0 4px 6px -2px rgb(0 0 0 / 12%), 0 2px 2px -1px rgb(0 0 0 / 5%);
    padding-bottom: 20px;
    .filter{
        display: flex;
        justify-items: center;
        align-items: center;
        justify-content: space-between;
    }
    .filDate{
        margin-bottom: 20px;
        display: flex;
        gap: 10px;
    }


    .info{
        display: flex;
        justify-content: end;
        .optionsTypeChart{
            cursor: pointer;
            align-content: end;
           
        }
    }
    .options{
       
        display: flex;
        justify-content: end;
        .op{
            width: 100px;
            position: absolute !important;
            z-index: 9000;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            li{
                border-bottom: 1px solid #d7d1d1;
                list-style: none;
                padding-top: 10px;
                cursor: pointer;
                padding: 6px;
            }
            li:hover{
                background-color: #0caff0;
            }
        }
    }
    h4{
        border-bottom: 2px solid #d7d1d1;
        padding-bottom: 10px;
    }
}