.recycleList{
    background-color: #f5f5f5;
    .list{
        width: 100%;
        // margin-right: 20px;
        th{
            height: 60px;
            text-align: center;
        }
        tr,td{
            border: 2px solid rgb(255, 255, 255);
        }
    }
}