.root {
  display: flex;
  justify-content: flex-end;
}

.addShippingFee {
  & .addShippingFeeTitle {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    margin-bottom: 10px;
    border: 1px solid #000;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  & .addShippingFeeTitle:hover {
    background-color: #f5f5f5;
    color: #179c49;
    transition: all 0.3s ease;
  }
}

.inputFile {
  & .inputFileTitle {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    margin-bottom: 10px;
    border: 1px solid #000;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  & .inputFileTitle:hover {
    background-color: #f5f5f5;
    color: #179c49;
    transition: all 0.3s ease;
  }

  & .inputFileInput {
    display: none;
  }
}
