.root {
  margin: 30px 0;
}

.title {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 20px 0;
  font-size: 36px;
  color: #179c49;
}

.filterWrap {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
