.root {
    height: 100vh;
    width: 100%;
    display: flex;
    margin-top: 30px;
    background-color: #FFFFFF;
    border-radius: 8px;
    .province{
        width: 20%;
        padding-right: 10px;
        padding-left: 10px;
        border-right: 1px solid #e4dfdf;
    }
    .district{
        width: 20%;
        padding-right: 10px;
        padding-left: 10px;
        height: 96%;
        overflow-y: scroll;
        border-right: 1px solid #e4dfdf;
        
    }
    .label{
        font-weight: 700;
    }
    .orders{
        width: 60%;
        height: 96%;
        overflow-y: scroll;
    }
    .tableOrder{
        width: 100%;
        text-align: left;
        th{
            padding: 16px;
        }
        tr{
            border-bottom: 1px solid #dddbdb;
        }
        td{
            padding: 10px;
            background-color: #FFFFFF;
            border: #f6f3f3;
        }
    }
  }
  
  h3 {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    font-size: 36px;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .pagination button {
    background-color: #4caf50;
    border: none;
    color: white;
    padding: 10px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
  }
  
  .pagination button[disabled] {
    opacity: 0.5;
    cursor: default;
  }
  .prevBtn {
    border-radius: 5px 0 0 5px;
  }
  
  .nextBtn {
    border-radius: 0 5px 5px 0;
  }
  