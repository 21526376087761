.inputWrap {
  margin: 20px 0;

  & .input {
    width: 100%;
  }
}

.buttonWrap {
  margin: 20px 0;
  display: flex;
  justify-content: space-evenly;
}
