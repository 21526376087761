.root {
  background-color: #F0F2F8;
  height: 100%;
  width: 100%;
  display: flex;
}

.sidebarWrap {
  width: 260px;
}

.contentWrap {
  width: calc(100% - 320px);
  padding: 20px;
}
