/* Define the styles for the root element */
.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  -moz-user-select: none !important;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;

  background-image: linear-gradient(to right, #20bf55, #01baef);

  opacity: 0.8;
}

/* Define the styles for the text center element */
.textCenter {
  text-align: center;
}

/* Define the styles for the title */
.title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #fff;
}

/* Define the styles for the description */
.description {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  color: #fff;
}

/* Define the styles for the warehouse select wrap */
.warehouseSelectWrap {
  display: flex;
  justify-content: center;
}

/* Define the styles for the warehouse select */
.warehouseSelect {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -1rem;
}

/* Define the styles for the warehouse select item */
.warehouseSelectItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 280px;
  margin: 1rem;
  background-color: #f2f2f2;
  border-radius: 5px;
  cursor: pointer;
  padding: 0 20px;
  transition: all 0.3s ease;
}

.warehouseSelectItem:hover {
  background-color: #dcdcdc;
  transform: scale(1.05);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

/* Define the styles for the warehouse select item icon */
.warehouseSelectItemIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70%;
  gap: 20px;

  & img {
    width: 100px;
  }
}

/* Define the styles for the warehouse select item icon text */
.warehouseSelectItemIconText {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 30%;
  text-align: center;
}

/* Define the styles for the warehouse select item icon text title */
.warehouseSelectItemIconTextTitle {
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: capitalize;
  margin-bottom: 0.5rem;
}

/* Define the styles for the warehouse select item icon text description */
.warehouseSelectItemIconTextDescription {
  font-size: 1.2rem;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading .loadingIcon {
  width: 100px;
  height: 100px;
  animation: loading 1s infinite;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
