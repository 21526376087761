.root {
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  box-shadow: 0 4px 6px -2px rgb(0 0 0 / 12%), 0 2px 2px -1px rgb(0 0 0 / 5%);
  border-radius: 8px;
  padding: 20px;
  padding-bottom: 0;
}

.loadingWrap {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 30px;
}

.pagination button {
  cursor: pointer;
}

.prevBtn {
  border-radius: 5px 0 0 5px;
}

.nextBtn {
  border-radius: 0 5px 5px 0;
}
