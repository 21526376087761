.root {
}

.orderListWrap {
  margin-top: 10px;

  & .orderList {
    display: flex;
    width: 100%;
    gap: 10px;
    background-color: #FFFFFF;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    // padding: 10px;
    border-radius: 10px;

    & table {
      width: 100%;

      & thead {
        & tr {
          
          & th {
            padding: 10px;
            color: #000;
            font-size: 16px;
            font-weight: 600;
            text-align: left;
            border: 1px solid #dde7e1;
          }
        }
      }

      & tbody {
        & tr {
          & td {
            padding: 10px;
            text-align: left;
            font-size: 14px;
            font-weight: 400;
            border: 1px solid #dde7e1;
          }
        }
      }
    }
  }
}

table {
  border-collapse: collapse;
}

table th,
td {
  border: 2px solid #fff;
}

th {
  border-top: none !important;
}

.tdAction {
  display: flex;
  gap: 20px;

  & div {
    background-color: #fff;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.2s ease-in-out all;
  }

  & div:hover {
    background-color: #f5f5f5;
    transition: 0.2s ease-in-out all;
  }

  & .pending {
    color: #179c49;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .canceled {
    color: #ff0000;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .finished {
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.loadingWrap {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noneData {
  text-align: center !important;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 10px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.pagination button[disabled] {
  opacity: 0.5;
  cursor: default;
}
.prevBtn {
  border-radius: 5px 0 0 5px;
}

.nextBtn {
  border-radius: 0 5px 5px 0;
}
