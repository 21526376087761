.item{
    height: 50px;
    td{
        text-align: left;
        padding-left: 20px;
    }
}
.action{
    display: flex;
    gap: 10px;
}