
.root {
    background-color: #FFFFFF;
    border-radius: 8px;
    height: 100vh;
    width: 100%;
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
    h2{
      color: #179C49;
      font-size: 36px;
    }
  }