.root {
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.SidebarWrap {
  padding: 40px 12px;
  & .Sidebar {
    & .SidebarHeader {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      flex-direction: column;
      align-items: center;
      border-bottom: 1px solid #179C49;
      padding-bottom: 20px;
      & .SidebarHeaderLogo {
        max-width: 200px;
        width: 100%;
        & img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.SidebarMenu {
  & .SidebarMenuItem {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px 10px;
    transition: 0.2s ease-in-out all;
    margin-bottom: 4px;

    & .SidebarMenuItemIcon {
      margin-right: 20px;
      display: flex;
      align-items: flex-end;

      & img {
        width: 22px;
        height: 22px;
      }
    }

    & .SidebarMenuItemText {
      font-size: 1rem;
      font-weight: 600;
      color: #1c1a1b;
      line-height: 31px;
    }
  }

  & .SidebarMenuItem:hover {
    background-color: #179C49;
    transition: 0.2s ease-in-out all;
    border-radius: 6px;
    & .SidebarMenuItemText{
      color: #ffffff;
    }
    & .SidebarMenuItemIcon img{
      filter: invert(1);
    }
  }

  & .SidebarMenuItem.active {
    background-color: #179C49;
    transition: 0.2s ease-in-out all;
    border-radius: 6px;
    & .SidebarMenuItemText{
      color: #ffffff;
    }
    & .SidebarMenuItemIcon img{
      filter: invert(1);
    }
  }
}

a {
  text-decoration: none;
}
