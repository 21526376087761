
.root {
    height: 100vh;
    width: 100%;
    padding-top: 50px;
    h2{
      color: rgb(8, 85, 40);
      font-size: 30px;
    }
    .pagination{
      float: right;
      margin: 20px 0;
    }
  }

  .orderListWrap {
    margin-top: 10px;
    & .statusTab {
      & .statusTabItem {
        display: flex;
        width: 100%;
        gap: 10px;
        & .statusTabItemTitle {
          width: 100%;
          padding: 4px 10px;
          text-align: center;
          cursor: pointer;
          height: 55px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #000;
          font-size: 16px;
          font-weight: 500;
        }
  
        & .statusTabItemTitle.active {
          background-color: #f5f5f5;
          color: #179c49;
        }
  
        & .statusTabItemTitle:hover {
          background-color: #f5f5f5;
          color: #179c49;
        }
      }
    }
  
    & .orderList {
      display: flex;
      width: 100%;
      gap: 10px;
      background-color: #f5f5f5;
  
      & table {
        width: 100%;
  
        & thead {
          & tr {
            & th {
              padding: 10px;
              color: #000;
              font-size: 16px;
              font-weight: 600;
              text-align: left;
            }
          }
        }
  
        & tbody {
          & tr {
            & td {
              padding: 10px;
              text-align: left;
              font-size: 14px;
              font-weight: 400;
            }
          }
        }
      }
    }
  }