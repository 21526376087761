.select{
    width: 100%;
    height: 30px;
}
.label{
    font-size: 14px;
    font-weight: 600;
}
.customSelect{
    margin-bottom: 10px;
}
.error{
    color: red;
}