// h3 center
.root {
  height: 100%;
  width: 100%;
  text-align: center;
}

h3 {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  font-size: 36px;
}
.chart{
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.customer{
  display: flex;
  text-align: left;
  h4{
    padding-bottom: 10px;
    border-bottom: 2px solid #b4b2b2;
  }
  .customerChart{
    background-color: #FFFFFF;
    padding: 0 20px;
    height: 400px;
    width: 500px;
    border-radius: 8px;
    box-shadow: 0 4px 6px -2px rgb(0 0 0 / 12%), 0 2px 2px -1px rgb(0 0 0 / 5%);
  }
}
