.overview{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .overItem{
      width: 23%;
      background-color: #ffffff;
      border-radius: 8px;
      box-shadow: 0 4px 6px -2px rgb(0 0 0 / 12%), 0 2px 2px -1px rgb(0 0 0 / 5%);
      height: 150px;
      position: relative;
      .title{
        display: flex;
        justify-content: space-between;
        padding: 0 10px;
        height: 75%;
        text-align: start;
        align-items: center;
        padding: 0 20px;
      }
      .des{
        position: absolute;
        width: 100%;
        bottom: 0;
        height: 25%;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
    .itemUser{
      .title{
        .number{
          h2{
            color: rgb(244, 161, 0);
          }
        }
        .icon{
          color: rgb(244, 161, 0);
        }
      }
      .des{
          background-color: rgb(244, 161, 0);
      }
    }
    .itemOrder{
      .title{
        .number{
          h2{
            color: #FF413A;
          }
        }
        .icon{
          color: #FF413A;
        }
      }
      .des{
          background-color: #FF413A;
      }
    }
    .itemWeight{
      .title{
        .number{
          h2{
            color: #01AC69;
          }
        }
        .icon{
          color: #01AC69;
        }
      }
      .des{
          background-color: #01AC69;
      }
    }
    .itemWeight1{
      .title{
        .number{
          h2{
            color: #3366FF;
          }
        }
        .icon{
          color: #3366FF;
        }
      }
      .des{
          background-color: #3366FF;
      }
    }
  }