.addOrder{
    .btn{
        float: right;
        margin: 20px 0;
        .btnAccept{
            margin-right: 20px;
        }
    }
    .title{
        text-align: center;
        margin-top: 10px;
        font-weight: 500;
    }
}
.processes{
    font-weight: 600;
    color: rgb(13, 138, 49);
}
.canCel{
    background-color: rgb(180, 16, 16);
}