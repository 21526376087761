.root {
  background-image: url(../../../public/images/9.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.error{
  color: red;
}

.loginWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  & .login {
    width: 400px;
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border: rgba(0, 0, 0, 0.1) solid 1px;
    padding: 60px;
    & .loginTitle {
      width: 100%;
      font-size: 2rem;
      text-align: center;
      margin-bottom: 40px;
      font-weight: 700;
      color: #57b27e;
    }
  }
}

.loginFormRow {
  & .loginFormRowWrap {
    width: 100%;
    margin-bottom: 15px;

    & .loginFormRowLabel {
      width: 25%;
      font-size: 1rem;
      color: #333;
      font-weight: 500;
    }

    & .loginFormRowInput {
      width: calc(100% - 10px);
      height: 40px;
      padding: 4px 10px;
      border-radius: 4px;
      font-size: 16px;
      outline: none;
    }

    & .buttonWrap {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      & .loginFormRowButton {
        width: 50%;
        height: 40px;
        background-color: #57b27e;
        border-radius: 8px;
        color: #fff;
        font-size: 1rem;
        font-weight: 500;
        border: none;
        cursor: pointer;
        margin-top: 60px;
        transition: all 0.3s ease-in-out;
      }

      & .loginFormRowButton:hover {
        background-color: #57b27e;
        opacity: 0.8;
        transition: all 0.3s ease-in-out;
      }
    }
  }
}

.notiError {
  color: red;
  font-size: 1rem;
  margin-top: 10px;
  padding: 0 10px;
  margin-bottom: 0px;
}
