.chart{
    text-align: left;
    padding: 0 20px;
    background-color: #fff;
    height: auto;
    width: 45%;
    border-radius: 8px;
    box-shadow: 0 4px 6px -2px rgb(0 0 0 / 12%), 0 2px 2px -1px rgb(0 0 0 / 5%);
    padding-bottom: 20px;
    h4{
        border-bottom: 2px solid #d7d1d1;
        padding-bottom: 10px;
    }
    table, th, td {
        border: 1px solid rgb(196, 190, 190);
        border-collapse: collapse;
        padding: 6px;
    }
}

.province{
    display: flex;
    align-items: center;
    gap: 4px;
    .add{
        font-size: 16px;
        cursor: pointer;
        border: 1px solid #d7d1d1;
        margin-top: 6px;
    }
}